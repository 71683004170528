<template>
    <modal ref="modalenviarCotizacion" titulo="Enviar cotización a proveedores" tamano="modal-xl" adicional="Enviar" @adicional="enviarCotizacion">
        <ValidationObserver ref="validator">
            <div class="d-flex gap-4 mx-4 mb-4">
                <div>
                    <ValidationProvider v-slot="{ errors }" name="referencia" rules="required|max:40">
                        <p class="input-label-top">Referencia</p>
                        <el-input v-model="model.referencia" size="small" class="input-left" placeholder="Referencia" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div>
                    <ValidationProvider v-slot="{ errors }" name="fecha" rules="required">
                        <p class="input-label-top">Fecha de vencimiento</p>
                        <el-date-picker v-model="model.fecha" type="date" value-format="yyyy-MM-dd" placeholder="Seleccionar un día" size="small" class="w-100" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div>
                    <ValidationProvider v-slot="{ errors }" name="credito" rules="required|numeric">
                        <p class="input-label-top">Días de crédito</p>
                        <el-input v-model="model.credito" size="small" class="input-left" placeholder="Dias de crédito" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
        <div class="mx-3 f-15">
            <titulo-divisor titulo="Resumen de cotización" class="mx-0">
                <div class="row">
                    <div class="col-auto my-auto px-1">
                        <p class="f-600 f-12">
                            Materiales:
                            <span class="f-300">{{ materiales() }}</span>
                        </p>
                    </div>
                    <div class="col-auto d-middle-center">
                        <div class="indicador-mint">
                            <div class="my-auto pr-2">
                                <p class="f-13">Valor estimado </p>
                            </div>
                            <div class="item">
                                <p>{{ formatoMoneda(valorEstimado()) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-middle-center">
                        <el-tooltip effect="light" content="Valor estimado según la ultima cotización de cada material" placement="bottom">
                            <i class="icon-information-outline f-20 ml-n3" />
                        </el-tooltip>
                    </div>
                </div>
            </titulo-divisor>
        </div>
        <div class="row mx-3 mb-3">
            <div class="col-3">
                <p class="f-14 f-600">Materiales</p>
            </div>
            <div class="col-auto">
                <p class="f-14 f-600">Proveedor favorito</p>
            </div>
        </div>
        <div v-for="(data,i) in cotizacionesEnviar.materiales" :key="i" class="row justify-content-center align-items-center mx-3 f-15 mb-2">
            <div class="col-3">
                <div class="bg-f5 border br-4 d-flex px-2 py-1 justify-content-between">
                    <p class="f-12 my-auto">{{ data.nombre }}</p>
                </div>
            </div>
            <div class="col-2">
                <div class="bg-f5 border br-4 d-flex px-2 py-1">
                    <p v-if="!data.proveedor_favorito" class="f-12 my-auto"> Sin proveedor </p>
                    <div v-else class="d-middle justify-content-between w-100">
                        <p class="f-12 my-auto">{{ data.proveedor_favorito }}</p>
                        <i class="icon-close-circle f-18 cr-pointer" @click="eliminarPvsFavorito(data)"/>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <el-select v-model="data.proveedores" filterable placeholder="Seleccionar proveedores" size="small" class="w-100" multiple @change="filterPvsFavorito(data)" >
                    <el-option v-for="item in cotizacionesEnviar.proveedores" :key="item.id" :label="item.proveedor" :value="item.id"/>
                </el-select>
            </div>
            <div class="col-auto px-0 d-middle gap-1">
                <el-tooltip content="Limpiar todos" effect="light" placement="right" visible-arrow>
                    <i class="icon-broom f-22 cr-pointer" @click="data.proveedores = []" />
                </el-tooltip>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data(){
        return {
            model: {
                fecha: null,
                credito: null,
            },
        }
    },
    created () {
        this.Action_get_cotizaciones_enviar(this.$route.params.id);
    },
    computed: {
        ...mapGetters({
            cotizacionesEnviar: 'oportunidades/ver/cotizaciones/cotizacionesEnviar'
        }),
    },
    methods: {
        ...mapActions({
            Action_get_cotizaciones_enviar: 'oportunidades/ver/cotizaciones/Action_get_cotizaciones_enviar',
            Action_enviar_cotizaciones: 'oportunidades/ver/cotizaciones/Action_enviar_cotizaciones',
            Action_get_cotizaciones_oportunidad: 'oportunidades/cotizaciones/cotizaciones/Action_get_cotizaciones_oportunidad',
        }),
        eliminarPvsFavorito(item){
            return item.proveedor_favorito = null
        },
        filterPvsFavorito(item){
            const pvs = item.proveedores.find(el => el == item.id_proveedor_favorito)
            if (pvs) {
                const pvsFavorito = this.cotizacionesEnviar.proveedores.find(el => el.id == pvs)
                item.proveedor_favorito = pvsFavorito.proveedor
                item.proveedores = item.proveedores.filter(el => el != pvs)
            }
            return item
        },
        materiales(){
            return this.cotizacionesEnviar?.materiales.length
            // let materiales = 0;
            // this.cotizacionesEnviar.materiales.forEach(el => {
            //     materiales += el.cantidad;
            // })
            // return materiales;
        },
        valorEstimado(){
            let valor = 0;
            this.cotizacionesEnviar.materiales.forEach(el => {
                valor += el.valor_ultima_cotizacion;
            })
            return valor;
        },
        toggle(){
            this.cleanModal()
            this.$refs.modalenviarCotizacion.toggle()
        },
        cleanDataModal(){
            this.cotizacionesEnviar.materiales.map(el => {
                el.proveedores = []
                if (!el.proveedor_favorito) {
                    el.proveedor_favorito = this.cotizacionesEnviar.proveedores.find(item => item.id == el.id_proveedor_favorito)?.proveedor ?? null
                }
                return el
            })
        },
        async enviarCotizacion(){
            const valid = await this.$refs.validator.validate();
            if (!valid) return
            const materiales = this.cotizacionesEnviar.materiales.map(el => {
                if (el.proveedor_favorito) {
                    el.proveedores.push(el.id_proveedor_favorito)
                }
                return {
                    id_material: el.id,
                    cantidad: el.cantidad,
                    proveedores: el.proveedores
                }
            })
            const payload = {
                fecha_vencimiento: this.model.fecha,
                dias_credito: this.model.credito,
                referencia: this.model.referencia,
                materiales
            }
            await this.Action_enviar_cotizaciones({id: this.$route.params.id, payload})
            await this.Action_get_cotizaciones_oportunidad({idOportunidad:this.$route.params.id, page:1})
            this.toggle()
        },
        cleanModal(){
            this.model.fecha = null
            this.model.credito = null
            this.$refs.validator.reset()
            this.cleanDataModal()
        }
    }
}
</script>

<style lang="scss" scoped>
.indicador-mint{
    border: 1px solid #3CD273;
    color: var(--color-5d);
    border-radius: 4px;
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    .item{
        background: #3CD273;
        border-radius: 0px 1px 1px 0px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        color: #fff;
    }
}
</style>

<style lang="scss">
.input-left{
    .el-input{
        input{
            text-align: left;
        }
    }
}
</style>