<template>
    <modal ref="modalVerEstado" titulo="Estado de cotizaciones" tamano="modal-lg">
        <div class="mx-4">
            <titulo-divisor titulo="Cotizaciones enviadas">
                <input-search v-model="query" auto-search placeholder="Buscar material en la lista" :search="getCotizacionesState"/>
            </titulo-divisor>
            <div v-for="(item, index) in cotizacionesEstado.cotizaciones" :key="index">
                <div class="bg-f5 border br-4 row px-2 py-1 mb-2">
                    <div class="col-8 my-auto h-100">
                        <p class="f-12 my-auto f-600 ">{{ item.proveedor }}</p>
                    </div>
                    <div class="col-2">
                        <div class="d-middle-center gap-1">
                            <i class="icon-format-list-numbered f-20" />
                            <p class="f-13">{{ item.cantidad_materiales_cotizados }} / {{ item.cantidad_materiales }}</p>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="d-middle-center gap-1">
                            <i :class="`${estado(item.estado)} f-20`" />
                            <p class="f-13">{{ item.estado }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data(){
        return {
            buscar: '',
        }
    },
    computed: {
        ...mapGetters({
            cotizacionesEstado: 'oportunidades/ver/cotizaciones/cotizacionesEstado'
        }),
        query: {
            get() {
                return this.$store.getters['oportunidades/ver/cotizaciones/queryState']
            },
            set(val){
                this.$store.commit('oportunidades/ver/cotizaciones/set_query_state', val)
            }
        },
    },
    methods: {
        ...mapActions({
            Action_get_cotizaciones_estado: 'oportunidades/ver/cotizaciones/Action_get_cotizaciones_estado'
        }),
        estado(estado){
            switch (estado) {
                case 'enviado':
                    return 'icon-file-send text-info'
                    break;
                case 'realizado':
                    return 'icon-file-send text-success'
                    break;
                default:
                    break;
            }
        },
        async getCotizacionesState(){
            await this.Action_get_cotizaciones_estado({idOps:this.$route.params.id, idCotizacion:this.$route.params.id_cotizacion});
        },
        async toggle(){
            await this.getCotizacionesState()
            this.$refs.modalVerEstado.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.indicador-mint{
    border: 1px solid #3CD273;
    color: var(--color-5d);
    border-radius: 4px;
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    .item{
        background: #3CD273;
        border-radius: 0px 1px 1px 0px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        color: #fff;
    }
}
</style>
