<template>
	<section class="mx-4">
		<titulo-divisor class="mx-2">
			<template slot="titulo">
				<div class="d-middle-center gap-1">
					<i 
                    v-if="$route.name !== 'oportunidades.ver.cotizaciones.cotizaciones'" 
                    class="el-icon-arrow-left cr-pointer" 
                    @click="$router.push({name: 'oportunidades.ver.cotizaciones.cotizaciones'})" 
                    />
					<p v-if="$route.name !== 'oportunidades.ver.cotizaciones.cotizaciones'" class="f-600 f-14 ucfirst ">{{this.$route.params.name_cotizacion}}</p>
				</div>
			</template>
            <div class="row">
				<div v-if="$route.name == 'oportunidades.ver.cotizaciones.cotizaciones'" class="row">
                    <!-- <button class="btn btn-general f-12 mr-2" @click="verCotizaciones">
                        Ver detalle
                    </button> -->
                    <div class="f-12 mr-2">
                        <input-search v-model="buscarCotizacion" auto-search placeholder="Buscar material en la lista" :search="getCotizacionesOporunidad"/>
                    </div>
                    <button class="btn btn-general f-12"
                        :class="permitAction('oportunidades.cotizaciones.enviar') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('oportunidades.cotizaciones.enviar', enviarCotizacion)"
                    >
                        Enviar a cotizar
                    </button>
                </div>
				<div v-else class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 mr-2 px-3" @click="verEstado">
                        Ver estado
                    </button>
					<el-popover v-model="popoverCotizacion" placement="bottom-end" width="360" trigger="click" class="px-0">
						<div class="row mx-0 justify-content-center f-600 text-general"
                            :class="permitAction('oportunidades.cotizaciones.habilitar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('oportunidades.cotizaciones.habilitar')"
                        >
							Habilitar cotización
						</div>
						<hr />
                        <ValidationObserver ref="validator">
                            <div class="row mx-0 justify-content-center my-2">
                                <div class="col-10 my-2">
                                    <p class="input-label-top">Proveedores</p>
                                    <ValidationProvider v-slot="{ errors }" name="cotización" rules="required">
                                        <el-select v-model="model.id_cotizacion" placeholder="Seleccionar proveedor" size="small" class="w-100">
                                            <el-option v-for="item in detalleProveedores" :key="item.id" :label="item.proveedor" :value="item.id" />
                                        </el-select>
                                        <vee-error :text="errors[0]"></vee-error>
                                    </ValidationProvider>
                                    <p class="input-label-top mt-4">Fecha Limite</p>
                                    <ValidationProvider v-slot="{ errors }" name="fecha" rules="required">
                                        <el-date-picker
                                            v-model="model.fecha_vencimiento"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="Seleccionar un día"
                                            size="small"
                                            class="w-100"
                                        />
                                        <vee-error :text="errors[0]"></vee-error>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
						<hr />
						<div class="text-right m-0">
							<button class="btn btn-cerrar f-12" @click="closePopover">Cerrar</button>
							<button class="btn btn-crear f-12 ml-2" @click="habilitarCotizacion">Habilitar</button>
						</div>
						<button slot="reference" class="btn btn-secondary f-12">
							Habilitar cotización
						</button>
					</el-popover>
                </div>
            </div>
        </titulo-divisor>
        <titulo-divisor v-if="$route.name == 'oportunidades.ver.cotizaciones.ver'" class="mx-2">
            <template slot="titulo">
                <div class="d-middle-center">
                    <p class="f-600 f-14 ucfirst">Materiales cotizados</p>
                </div>
            </template>
            <div class="row">
                <div>
                    <input-search v-model="query" auto-search placeholder="Buscar material en la lista" :search="getMaterials"/>
                </div>
                <el-tooltip content="Filtro" effect="light" :visible-arrow="false">
                    <filter-indicator
                    :count="filterCount"
                    @openModalFilter="abrirModalFiltros"
                    @clearFilter="limpiarFiltro"
                    />
                </el-tooltip>
            </div>
        </titulo-divisor>
		<!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11">
                    <p class="input-label-top">Filtro materiales por:</p>
                </div>
                <div class="col-11 mt-3 mb-4">
                    <p class="input-label-top">Proveedor</p>
                    <el-select v-model="filter.id_proveedor" placeholder="Seleccionar proveedor" size="small" class="w-100" clearable>
                        <el-option v-for="item in select_providers" :key="item.id" :label="item.proveedor" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11">
                    <el-checkbox v-model="filter.materiales_no_cotizados" :true-label="1" :false-label="0" label="Materiales no cotizados"></el-checkbox>
                    <el-checkbox v-model="filter.materiales_cotizados" :true-label="1" :false-label="0" label="Materiales cotizados"></el-checkbox>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" placeholder="Seleccionar tipo" size="small" class="w-100" clearable>
                        <el-option v-for="item in select_material_types" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 mt-3">
                    <p class="input-label-top">Ordenar proveedor por:</p>
                </div>
                <div class="col-11 mt-3">
                    <el-radio-group v-model="filter.ordenar_por">
                        <el-radio class="w-100 mb-2" :label="1">Nombre</el-radio>
                        <el-radio class="w-100 mb-2" :label="2">Tipo</el-radio>
                        <el-radio class="w-100 mb-2" :label="3">Cantidad</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
		<router-view />
		<modal-enviar-cotizacion ref="abrirEnviarCotizacion" />
		<modal-ver-estado ref="modalVerEstado" />
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalEnviarCotizacion from '../../partials/modalEnviarCotizacion'
import modalVerEstado from './partials/modalVerEstado'
export default {
	components: {
		modalEnviarCotizacion,
		modalVerEstado
	},
	data() {
		return {
            model: {
                fecha_vencimiento: null,
                id_cotizacion: null
            },
			popoverCotizacion: false,
            buscarListado: '',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                },
            ],
            radio: 3,
            buscarCotizacion:''
		}
	},
    async created(){
        Promise.all([
            this.Action_get_cotizaciones(this.$route.params.id),
            this.Action_get_detalle_proveedores(this.$route.params.id)
        ])
        this.getSelects(['Action_get_select_material_types', 'Action_get_select_providers'])
    },
    watch: {
        "$route.name"(val){
            this.clearFilter()
            this.query = null
        }
    },
    computed: {
        ...mapGetters({
            select_material_types: 'selects/selects/select_material_types',
            select_providers: 'selects/selects/select_providers',
            detalleProveedores: 'oportunidades/ver/cotizaciones/detalleProveedores',
        }),
        filter: {
            get() {
                return this.$store.getters['oportunidades/ver/cotizaciones/filter']
            },
            set(val){
                this.$store.commit('oportunidades/ver/cotizaciones/set_filter', val)
            }
        },
        query: {
            get() {
                return this.$store.getters['oportunidades/ver/cotizaciones/query']
            },
            set(val){
                this.$store.commit('oportunidades/ver/cotizaciones/set_query', val)
            }
        },
        pagination: {
            get() {
                return this.$store.getters['oportunidades/ver/cotizaciones/pagination']
            },
            set(val){
                this.$store.commit('oportunidades/ver/cotizaciones/set_pagination', val)
            }
        },
        paginationDetail: {
            get() {
                return this.$store.getters['oportunidades/ver/cotizaciones/paginationDetail']
            },
            set(val){
                this.$store.commit('oportunidades/ver/cotizaciones/set_pagination_detail', val)
            }
        },
        filterCount(){
            return this.filter.count()
        }
    },
	methods: {
        ...mapActions({
            Action_get_cotizaciones: 'oportunidades/ver/cotizaciones/Action_get_cotizaciones',
            Action_get_detalle_proveedores: 'oportunidades/ver/cotizaciones/Action_get_detalle_proveedores',
            Action_get_detalle_cotizaciones: 'oportunidades/ver/cotizaciones/Action_get_detalle_cotizaciones',
            Action_cotizaciones_habilitar: 'oportunidades/ver/cotizaciones/Action_cotizaciones_habilitar',
            getSelects: 'selects/selects/sync',
            clearFilter: 'oportunidades/ver/cotizaciones/clearFilter',
            Action_get_cotizaciones_oportunidad: 'oportunidades/cotizaciones/cotizaciones/Action_get_cotizaciones_oportunidad',
        }),
		enviarCotizacion(){
            this.$refs.abrirEnviarCotizacion.toggle()
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
		verEstado(){
            this.$refs.modalVerEstado.toggle()
		},
        closePopover(){
            this.popoverCotizacion = false
            this.$refs.validator.reset()
            this.model.fecha_vencimiento = null
            this.model.id_cotizacion = null      
        },
		async habilitarCotizacion(){
            const valid = await this.$refs.validator.validate();
            if (!valid) return
            await this.Action_cotizaciones_habilitar({id: this.$route.params.id, payload: this.model})
            this.closePopover()
        },
        getMaterials(){
            if(this.$route.name == 'oportunidades.ver.cotizaciones.materiales'){
                this.pagination.current_page = 1
                this.Action_get_cotizaciones({idOportunidad:this.$route.params.id, idCotizacion:this.$route.params.id_cotizacion})
            } else {
                this.paginationDetail.current_page = 1
                this.Action_get_detalle_cotizaciones({idOportunidad:this.$route.params.id, idCotizacion:this.$route.params.id_cotizacion})
            }
        },
        async filtrarMateriales(){
            if(this.$route.name == 'oportunidades.ver.cotizaciones.materiales') await this.Action_get_cotizaciones({idOportunidad:this.$route.params.id, idCotizacion:this.$route.params.id_cotizacion})
            else await this.Action_get_detalle_cotizaciones({idOportunidad:this.$route.params.id, idCotizacion:this.$route.params.id_cotizacion})
            this.$refs.abrirModalFiltros.toggle()
        },
        async limpiarFiltro(){
            this.clearFilter()
            await this.Action_get_cotizaciones(this.$route.params.id)
        },
        verCotizaciones(){
            this.$router.push({name: 'oportunidades.ver.cotizaciones.ver'})
        },
        getCotizacionesOporunidad(){
            const buscar = this.buscarCotizacion
            this.Action_get_cotizaciones_oportunidad({idOportunidad:this.$route.params.id, buscar:buscar})
        }
	},

}
</script>

<style>

</style>